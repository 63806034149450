import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
import { ILogger } from '../../types/logger-type';

@Injectable({
	providedIn: 'root'
})
export class NetworkService {

	private log: ILogger;
	private baseHref: string;

	constructor(private http: HttpClient, private loggerSrv: LoggerService) {
		this.log = this.loggerSrv.get('NetworkService');
		this.baseHref = '/' + (!!window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] + '/' : '');
	}

	async setRecording(sessionId: string, openviduServerUrl: string, openviduSecret: string): Promise<string> {		
		try {
			let n;
			const regex2 = /^sagicc\-([aA-zZ\.\_\-]*)-room-([0-9]*)/gm;			
			const str2=sessionId;	
			let subDomain2 = '-2';
			let casoId = "-1";

			while ((n = regex2.exec(str2)) !== null) {
				// This is necessary to avoid infinite loops with zero-width matches
				if (n.index === regex2.lastIndex) {
					regex2.lastIndex++;
				}
				// The result can be accessed through the `m`-variable.
				n.forEach((match, groupIndex) => {
					if(groupIndex === 1) {
						subDomain2 = match;
					}
					if(groupIndex === 2) {
						casoId = match;
					}
				});
			}
			sessionId = sessionId.replace('.','_');
			if(subDomain2 === "-2") {
				throw {status: 401, message: 'Subdominio invalido'};
			}
			let sagiccHref = "https://" + subDomain2 + ".sagicc.co/api/v2/canal-videochat/ov/externo";
			return await this.http.post<any>(sagiccHref, {
				subdomain: subDomain2,
				sessionId: sessionId,
				casoId: casoId
			}).toPromise();
		} catch (error) {
			if (error.status === 404) {
				throw {status: error.status, message: 'Cannot connect with backend'};
			}
			throw error;
		}
	}

	async getToken(sessionId: string, openviduServerUrl: string, openviduSecret: string): Promise<string> {
		if (!!openviduServerUrl && !!openviduSecret) {
			const _sessionId = await this.createSession(sessionId, openviduServerUrl, openviduSecret);
			return await this.createToken(_sessionId, openviduServerUrl, openviduSecret);
		}
		try {
			this.log.d('Getting token from backend');
			const regex = /^https:\/\/([aA-zZ0-9\.\_]*)\.sagicc\.co[\/\=\-aA-zZ0-9]*$/gm;
			const regex2 = /^sagicc\-([aA-zZ\.\_\-]*)-room-[0-9]*/gm;
			const str = document.referrer;
			const str2=sessionId;	
			let m,n;

			let subDomain1 = '-1';
			let subDomain2 = '-2';

			while ((m = regex.exec(str)) !== null) {
				// This is necessary to avoid infinite loops with zero-width matches
				if (m.index === regex.lastIndex) {
					regex.lastIndex++;
				}
				// The result can be accessed through the `m`-variable.
				m.forEach((match, groupIndex) => {
					if(groupIndex === 1) {
						subDomain1 = match;
					}
				});
			}
			while ((n = regex2.exec(str2)) !== null) {
				// This is necessary to avoid infinite loops with zero-width matches
				if (n.index === regex2.lastIndex) {
					regex2.lastIndex++;
				}
				// The result can be accessed through the `m`-variable.
				n.forEach((match, groupIndex) => {
					if(groupIndex === 1) {
						subDomain2 = match;
					}
				});
			}	
			
			if(subDomain1 != subDomain2) {
				throw {status: 401, message: 'Por favor ingrese con el enlace suministrado'};
			}
			sessionId = sessionId.replace('.','_');
			return await this.http.post<any>(this.baseHref + 'call', {sessionId}).toPromise();
		} catch (error) {
			if (error.status === 404) {
				throw {status: error.status, message: 'Cannot connect with backend. ' + error.url + ' not found'};
			}
			throw error;
		}
	}

	createSession(sessionId: string, openviduServerUrl: string, openviduSecret: string): Promise<string> {
		return new Promise((resolve, reject) => {			
			const body = JSON.stringify({ customSessionId: sessionId, recordingMode: 'ALWAYS', defaultOutputMode: 'COMPOSED' });
			const options = {
				headers: new HttpHeaders({
					Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + openviduSecret),
					'Content-Type': 'application/json'
				})
			};
			return this.http
				.post<any>(openviduServerUrl + '/api/sessions', body, options)			
				.pipe(
					catchError(error => {
						if (error.status === 409) {
							resolve(sessionId);
						}
						if (error.statusText === 'Unknown Error') {
							reject({status: 401, message: 'ERR_CERT_AUTHORITY_INVALID'});
						}
						return observableThrowError(error);
					})
				)
				.subscribe(response => {
					let subdomain = document.referrer;
					this.http
					.post<any>(openviduServerUrl + '/api/sessions', body, options)			
					.pipe(
						catchError(error => {							
							return observableThrowError(error);
						})
					)
					.subscribe(response2 => {
						console.log("OK")
					});
					resolve(response.id);
				});
		});
	}

	createToken(sessionId: string, openviduServerUrl: string, openviduSecret: string): Promise<string> {
		return new Promise((resolve, reject) => {
			const body = JSON.stringify({ session: sessionId });
			const options = {
				headers: new HttpHeaders({
					Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + openviduSecret),
					'Content-Type': 'application/json'
				})
			};
			return this.http
				.post<any>(openviduServerUrl + '/api/tokens', body, options)
				.pipe(
					catchError(error => {
						reject(error);
						return observableThrowError(error);
					})
				)
				.subscribe(response => {
					this.log.d(response);
					resolve(response.token);
				});
		});
	}
}

<div id="roomConfig" [ngStyle]="{ display: (ovSettings | isAutoPublish) ? 'none' : 'block' }">
<!-- Modal -->
<div class="modal" *ngIf="showConfigCard">
    <div class="modal-dialog modal-xl">
      <div class="modal-content cardContainer">

        <!-- Modal Header -->
        <div class="modal-header cardHeader">
			<div class="headerLogo">
				<img
					id="header_img"
					alt="Logo Sagicc"
					src="https://sagicc_dev.sagicc.co/images/lettering.svg"
				/>
			</div>
			<h3 class="headerTitle">{{ mySessionId }}</h3>

			<button mat-mini-fab (click)="close()" class="closeButton">
				<mat-icon color="warn" matTooltip="Close">highlight_off</mat-icon>
			</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body" #bodyCard>

		  <div class="row align-items-center">
			<div class="col-sm-6 col-md-6 col-lg-6 leftSection">
				<div class="videoContainer">

				<!-- <mat-slider
						class="volumeSlider"
						*ngIf="volumeValue < 100"
						[max]="0"
						[min]="100"
						[color]="updateVolumeColor()"
						[ngModel]="volumeValue"
						[vertical]="true"
					>
					</mat-slider> -->

					<div *ngFor="let localUser of localUsers; let i = index">
						<!-- Only webcam video will be shown if webcamera is available -->
						<ov-video
							*ngIf="(localUser.isCamera() && hasVideoDevices) || localUser.isScreen()"
							[streamManager]="localUser.streamManager"

							[className]="localUsers.length > 1 && i === 0 ? 'ovVideoSmall' : ''"
						></ov-video>
						<div class="cameraMessageContainer" *ngIf="localUser.isCamera() && !hasVideoDevices">
							<span>Lo Sentimos. Cámara no disponible</span>
						</div>
					</div>
				</div>

				<div class="avatarButton">
					<button mat-stroked-button (click)="takePhoto()">
						<mat-icon matTooltip="Take Photo">photo_camera</mat-icon>
						<span style="margin-left: 5px;">Capturar Avatar</span>
					</button>
				</div>
			</div>

			<div class="col-sm-6 col-md-6 col-lg-6 rightSection">
				<div class="avatarContainer">
					<h3>Avatar</h3>

				</div>


				<div fxLayout="row" fxFill id="avatarSection" *ngIf="hasVideoDevices">

					<div class="" fxFlex="100" fxLayoutAlign="center center">


						<div
							id="avatarContainer"
							(click)="setAvatar('video')"
							[style.background]="avatarSelected === 'video' ? 'lightgreen' : 'transparent'"
						>
							<div id="imgText" *ngIf="!videoAvatar">
								<span></span>
							</div>
							<img id="avatarImg" *ngIf="videoAvatar" [src]="videoAvatar" />
						</div>
						<div
							id="avatarContainer"
							(click)="setAvatar('random')"
							[style.background]="avatarSelected === 'random' ? 'transparent' : 'transparent'"
						>
							<mat-spinner id="imgText" [diameter]="70" *ngIf="!randomAvatar" color="accent"></mat-spinner>
							<img id="avatarImg" *ngIf="randomAvatar" [src]="randomAvatar" />
						</div>
					</div>

				</div>

				<!-- Devices section / Nickname-->
				<div fxLayout="row" fxFill id="devicesSection">
					<div fxLayout fxFlex>
						<div class="one" fxFlex="20" fxLayoutAlign="center center">
							<button mat-mini-fab (click)="setNicknameForm()" class="deviceButton">
								<mat-icon matTooltip="Usuario">person</mat-icon>
							</button>
						</div>
						<div class="two" fxFlex="80" fxLayoutAlign="center center">
							<form id="nicknameForm" class="alternate-theme">
								<mat-form-field>
									<input
										matInput
										placeholder="Usuario"
										[formControl]="nicknameFormControl"
										[errorStateMatcher]="matcher"
										(keypress)="eventKeyPress($event)"
										autocomplete="off"
									/>
									<mat-error *ngIf="nicknameFormControl.hasError('required')">
										Usuario es is <strong>requrido</strong>
									</mat-error>
									<mat-error *ngIf="nicknameFormControl.hasError('maxlength')">
										Usuario es <strong>muy extenso!</strong>
									</mat-error>
								</mat-form-field>
							</form>
						</div>
					</div>
				</div>

				<!-- Devices section / Microphone-->
				<div fxLayout="row" fxFill id="devicesSection" *ngIf="hasAudioDevices && (ovSettings | hasAudio)">
					<div fxLayout fxFlex>
						<div class="one" fxFlex="20" fxLayoutAlign="center center">
							<button mat-mini-fab (click)="toggleMic()" class="deviceButton">
								<mat-icon *ngIf="isAudioActive" matTooltip="Habilitar Micrófono">mic</mat-icon>
								<mat-icon *ngIf="!isAudioActive" color="warn" matTooltip="Deshabilitar Micrófono">mic_off</mat-icon>
							</button>
						</div>
						<div class="two" fxFlex="80" fxLayoutAlign="center center">
							<mat-form-field class="alternate-theme">
								<mat-select
									placeholder="Micrófono"
									[ngModel]="isAudioActive && micSelected ? micSelected.device : 'None'"
									(selectionChange)="onMicrophoneSelected($event)"
								>
									<mat-option *ngFor="let microphone of microphones" [value]="microphone.device">
										{{ microphone.label }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</div>
				<!-- Devices section / Camera-->
				<div fxLayout="row" fxFill id="devicesSection" *ngIf="hasVideoDevices && (ovSettings | hasVideo)">
					<div fxLayout fxFlex>
						<div class="one" fxFlex="20" fxLayoutAlign="center center">
							<button mat-mini-fab (click)="toggleCam()" class="deviceButton">
								<mat-icon *ngIf="isVideoActive" matTooltip="Habilitar Cámara">videocam</mat-icon>
								<mat-icon *ngIf="!isVideoActive" color="warn" matTooltip="Deshabilitar Cámara">videocam_off</mat-icon>
							</button>
						</div>
						<div class="two" fxFlex="80" fxLayoutAlign="center center">
							<mat-form-field class="alternate-theme">
								<mat-select
									placeholder="Cámara"
									[ngModel]="isVideoActive && camSelected ? camSelected.device : 'None'"
									(selectionChange)="onCameraSelected($event)"
								>
									<mat-option *ngFor="let camera of cameras" [value]="camera.device">
										{{ camera.label }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</div>

				<!-- Devices section / ScreenShare-->
				<!--
				<div fxLayout="row" fxFill id="devicesSection" *ngIf="ovSettings | hasScreenSharing">
					<div fxLayout fxFlex>
						<div class="one" fxFlex="20" fxLayoutAlign="center center">
							<button mat-mini-fab (click)="toggleScreenShare()" class="deviceButton">
								<mat-icon *ngIf="this.oVSessionService.isScreenShareEnabled()" matTooltip="Screen Share Enabled"
									>screen_share</mat-icon
								>
								<mat-icon
									*ngIf="!this.oVSessionService.isScreenShareEnabled()"
									color="warn"
									matTooltip="Screen Share Disabled"
								>
									stop_screen_share</mat-icon
								>
							</button>
						</div>
						<div class="two" fxFlex="80" fxLayoutAlign="center center">
							<mat-form-field class="alternate-theme">
								<input
									matInput
									disabled
									placeholder="Screen"
									[ngModel]="this.oVSessionService.isScreenShareEnabled() ? 'Screen' : 'None'"
								/>
							</mat-form-field>
						</div>
					</div>
				</div>-->
			</div>
		  </div>

        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="justify-content: center">
			<button mat-flat-button color="accent" (click)="joinSession()" form="nicknameForm" id="joinButton">INGRESAR</button>

        </div>

      </div>
    </div>
  </div>
</div>


<div class="section1">
	<mat-toolbar id="header">
	</mat-toolbar>
	<div class="container">
		<div class="ovInfo">
			<img class="ovLogo" alt="Sagicc" src="https://sagicc_dev.sagicc.co/images/lettering.svg" width="500" />
			<h4>Atención al cliente omnicanal</h4>
		</div>
	</div>


	<mat-toolbar class="footer">
		<span>
			Sagicc by TechniSupport
		</span>
	</mat-toolbar>
</div>
